<template>
    <aside id="sidebar" class="sidebar" :class="sidebarIsOpen ? 'is-open': ''" >
        <div class="container-sidebar ">
            <div class="sidebar-head">
                <figure class="sidebar-head-logo">
                    <img :src="require('@/assets/images/logo/Logo_TFC_Negro.png')" alt="logo" class="sidebar-head__img">
                </figure>
                <svg width="26" height="26" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" @click="closeSidebar">
                    <path d="M1.5 1.5L13.5 13.5M1.5 13.5L13.5 1.5" stroke="currentColor"/>
                </svg>
            </div>

            <div class="myContainer">
                <ul class="nav__list">
                    <li class="nav__item" v-for="(item, index) in routes" :key="index" :class="activeClass(item.name)" v-show="item.show">
                        <a class="nav__link" :class="item.name == $route.name ? 'is-active': ''" @click="pushRoute(item.name, item.action)">
                            <component :is="item.icon"></component> {{ item.display }}
                        </a>
                    </li>
                </ul>
            </div>

            <div class="sidebar-footer">
                <button @click="skin = isDark ? 'light' : 'dark'">
                    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.66077 11.3619C2.09296 11.4524 2.54093 11.5 3.00002 11.5C6.58987 11.5 9.50002 8.58984 9.50002 4.99999C9.50002 3.25479 8.81224 1.67024 7.69295 0.502594C11.4697 0.604809 14.5 3.69852 14.5 7.49999C14.5 11.366 11.366 14.5 7.49999 14.5C5.06138 14.5 2.91401 13.253 1.66077 11.3619Z" stroke="currentColor" stroke-linejoin="round"/>
                    </svg>
                </button>
                <button @click="_logout">
                    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.5 7.5L5.5 3.5M1.5 7.5L5.5 11.5M1.5 7.5L12 7.5M13.5 14V1" stroke="currentColor"/>
                    </svg>
                </button>
            </div>
        </div>
    </aside>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import useAppConfig from '@core/app-config/useAppConfig'
import { computed } from 'vue'

import MenuIcon from '@/modules/layouts/components/icons/MenuIcon'
import CakeIcon from '@/modules/layouts/components/icons/CakeIcon'
import BilleteIcon from '@/modules/layouts/components/icons/BilleteIcon'
import CalendarIcon from '@/modules/layouts/components/icons/CalendarIcon'
import BarChartIcon from '@/modules/layouts/components/icons/BarChartIcon'
import UserIcon from '@/modules/layouts/components/icons/UserIcon'
import FilesIcon from '@/modules/layouts/components/icons/FilesIcon'
import SunIcon from '@/modules/layouts/components/icons/SunIcon'
import UsersIcon from '@/modules/layouts/components/icons/UsersIcon'

import { utils } from "@/modules/owners/mixins/utils"
import { auth } from '@/modules/auth/mixins/auth'
import HomeIcon from '@/modules/layouts/components/icons/HomeIcon'
import ShuffleIcon from '@/modules/layouts/components/icons/ShuffleIcon'


export default {
    mixins:[utils, auth],
    props:{
        sidebarIsOpen: {
            type: Boolean,
            required: true,
        },
        routes:{
            type:Array,
            required: true,
        }
    },
    components: {
        MenuIcon,
        CakeIcon,
        BilleteIcon,
        CalendarIcon,
        BarChartIcon,
        UserIcon,
        FilesIcon,
        HomeIcon,
        ShuffleIcon,
        SunIcon,
        UsersIcon,
    },
    computed: {
        ...mapState('appConfig',['windowWidth',]),
        ...mapState('auth',['user','userAdmin','myContracts']),
    },
    methods: {
	    ...mapActions('owners', ['pushActionsService']),
        activeClass: function (...names) {
            for (let name of names){
                return name == this.$route.name ? 'item-active': ''
            }
        },
        openSidebar(){
            this.$emit('open-sidebar')
        },
        closeSidebar(){
            this.$emit('close-sidebar')
        },
        _logout(){
            if(this.userAdmin){
                this.$router.push({name: 'admin'})
                return
            }
            this.normalLogout()
        },
        async pushRoute(nameRoute, action){
            const previousRoute =  this.$route.name
            this.$router.push({name: nameRoute })
            await this.pushData('onClick', action, action, previousRoute) // push a back del registro
        },

    },
    setup() {
        const { skin } = useAppConfig()

        const isDark = computed(() => skin.value === 'dark')

        return { skin, isDark }
    },
    watch: {
        windowWidth:{
            immediate: true,
            handler(){
                if(this.windowWidth >= 960 ){
                    this.$emit('open-sidebar')
                }else {
                    this.$emit('close-sidebar')
                }
            }
        },
        $route (){
            if(this.windowWidth <= 960 ){
                this.$emit('close-sidebar') // si la ruta cambia, cierro el sidebar y el overlay desaparece
            }
        }

    },
}
</script>

<style lang="scss" scoped>


.myContainer{
    margin: 1rem;
}

// estilos del sidebar
.sidebar{
    background: #fff;
    color: #000;
    height: 100%;
    left: -280px; // pasa a 0 cuando se abre el sidebar
    margin: 0;
    top: 0;
    position: fixed;
    // width: 50%;
    width: 280px;
    z-index: 10;
    -webkit-transition: all 0.2s cubic-bezier(0.17, 0.67, 0.83, 0.67);
    -moz-transition: all 0.2s cubic-bezier(0.17, 0.67, 0.83, 0.67);
    transition: all 0.2s cubic-bezier(0.17, 0.67, 0.83, 0.67);
    // transition: all 0.5s ease;
    // ocultar el scrollbar en IE
    -ms-overflow-style: none;
    // ocultar el scrollbar en Firefox
    scrollbar-width: none;
}

.sidebar.is-open{
    left: 0; // es 0 cuando el sidebar está
}
.container-sidebar{
    height: 100%;
    display: grid;

    grid-template-rows: 100px  1fr 70px;
    grid-template-columns: 1fr;
    grid-template-areas:
    "header"
    "navigation"
    "footer";
    gap: 1rem;
}

.sidebar-head{
    position: relative;
    // padding: 1rem;
    display: flex;
    // justify-content: space-between;
    align-items: center;
}

.sidebar-head svg{
    width: 26px;
    height: 26px;
    cursor: pointer;
}
.sidebar-head-logo{
    max-width: 200px;
}
.sidebar-head__img {
    display: block;
    width: 100%;
}
.sidebar-head svg {
    position: absolute;
    cursor: pointer;
    right: .5rem;
    top: 1.2rem;
}
.sidebar-head svg:first-of-type{
    margin-right: 1rem;
}
.nav__list {
    margin: 0;
    display: flex;
    flex-direction: column;
    // row-gap: 1rem;
    transition: all 0.5s ease;
    padding: 0;
    list-style: none;
    list-style-type: none;
}

.nav__item{
  background-color: transparent;
//   background: linear-gradient(118deg,#231f20,rgba(35,31,32,.7));
  transition: all 0.5s ease;
  width: 90%;
  border-radius: 4px;
  padding: 1rem 2rem;
}
.nav__item.item-active{
  background: linear-gradient(118deg,#231f20,rgba(35,31,32,.7));
  color: white;
}
.nav__link {
  font-weight: 500;
  text-transform: uppercase;
  font-size: .9rem;
  display: flex;
}

.nav__link svg {
    margin-right: 1rem;
}
.nav__link.is-active {
    color: white;
}

.sidebar-footer{
    display: flex;
    justify-content: space-between;
    margin: 1rem 1rem;
}

.sidebar-footer button{
    border: 0;
    border-radius: 50%;
    width: 45px;
    height: 45px;
}

.sidebar-footer button:first-of-type {
    background-color: #14142B;
    color: #FCFCFC;
}

.sidebar-footer button:last-of-type {
    background-color: #4338ca;
    color: #FCFCFC;
}

@media (min-width:960px) {

    .sidebar-head svg{
        width: 20px;
        height: 20px;
    }
}

</style>