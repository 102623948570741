export function benefitsFirstGroup( state ) {
    let benefits = []
    const year = state.yearsBenefitsList[0]
    if( year ){
        benefits = state.benefitsToUseInBooking.filter(benefit => benefit.year === year )
    }
    return benefits
}

export function benefitsSecondGroup( state ) {
    let benefits =[]
    const year = state.yearsBenefitsList[1] 
    if( year ){
        benefits = state.benefitsToUseInBooking.filter(benefit => benefit.year === year )
    }
    return benefits
}

export function benefitsShowReadOnlyModeGroupOne( state ) {
    let benefits =[]
    const year = state.yearsBenefitsList[0]     
    if( year ){
        benefits = state.benefitsToUseInBooking.filter(benefit => benefit.year === year  && benefit.qty > 0 )
    }
    return benefits      
}

export function benefitsShowReadOnlyModeGroupTwo( state ) {
    let benefits =[]
    const year = state.yearsBenefitsList[1]     
    if( year ){
        benefits = state.benefitsToUseInBooking.filter(benefit => benefit.year === year && benefit.qty > 0  )
    }
    return benefits      
}
