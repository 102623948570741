import { isAuthenticatedGuard, promotionsRequiresParameterAuth, suscriptionsModulesAuth } from '../../auth/router/auth-guard'

export default [
    {
        path: '/admin-users',
        name: 'admin',
        component: () => import('@/modules/owners/views/AdminUsers'),
        beforeEnter: isAuthenticatedGuard,
        meta: {
            layout: 'LoginAdminLayout',
        },
    },
    {
        path: '/home',
        name: 'home',
        component: () => import('@/modules/owners/views/Dashboard'),
        beforeEnter: isAuthenticatedGuard,
        meta: {
            layout: 'AdminLayout',
        },
    },
    {
        path: '/my-benefits',
        name: 'my-benefits',
        component: () => import('@/modules/owners/views/MyBenefits'),
        beforeEnter: isAuthenticatedGuard,
        meta: {
            layout: 'AdminLayout',
        },
    },
    {
        path: '/my-reservations',
        name: 'my-reservations',
        component: () => import('@/modules/owners/views/MyReservations'),
        beforeEnter: isAuthenticatedGuard,
        meta: {
            layout: 'AdminLayout',
        },
    },
    {
        path: '/my-reservations/edit/:id',
        name: 'edit-booking',
        component: () => import('@/modules/owners/views/EditReservations'),
        beforeEnter: isAuthenticatedGuard,
        meta: {
            layout: 'AdminLayout',
            requiresAuth: true,
        }
    },
    {
        path: '/my-reports',
        name: 'my-reports',
        component: () => import('@/modules/owners/views/MyReports'),
        beforeEnter: isAuthenticatedGuard,
        meta: {
            layout: 'AdminLayout',
        },
    },
    {
        path: '/condomimium',
        name: 'condomimium',
        component: () => import('@/modules/owners/views/ComingSoon'),
        beforeEnter: isAuthenticatedGuard,
        meta: {
            layout: 'AdminLayout',
        },

    },
    {
        path: '/resources',
        name: 'resources',
        component: () => import('@/modules/owners/views/Resources'),
        beforeEnter: isAuthenticatedGuard,
        meta: {
            layout: 'AdminLayout',
        },
    },
    {
        path: '/promotions',
        name: 'promotions',
        component: () => import('@/modules/owners/views/SocialPromos'),
        beforeEnter: promotionsRequiresParameterAuth,
        meta: {
            layout: 'AdminLayout',
        },
    },
    {
        path: '/exchanges',
        name: 'exchanges',
        component: () => import('@/modules/owners/views/Exchanges'),
        beforeEnter: suscriptionsModulesAuth,
        meta: {
            layout: 'AdminLayout',
        },
    },
    {
        path: '/about-us',
        name: 'about-us',
        component: () => import('@/modules/owners/views/OurTeam'),
        beforeEnter: isAuthenticatedGuard,
        meta: {
            layout: 'AdminLayout',
        },
    },
    {
        path: '/not-found',
        name: 'not-found',
        component: () => import ('@/modules/owners/views/Error404.vue'),
        beforeEnter: isAuthenticatedGuard,
        meta: {
            layout: 'AdminLayout',
        },
    },
    {
        path: '/terms-conditions/:term/:lang',
        name: 'termsConditions',
        component: () => import('@/modules/owners/views/TermsConditions'),
        // beforeEnter: isAuthenticatedGuard,
    },

]

