import { consoleMsgFinally, formatBenefits, formatArrayFileDocuments, splitArrayFileDocuments, orderBookingsByDateInAsc, showAlertMessage } from '@/helpers/helpers'
import axiosClient from '@/libs/axios'
import { getPhotosGalleryUser } from '@/modules/auth/store/actions'
import { fetchLanguages } from '@/modules/market/store/actions'

import store from '@/store'


export const getHotels = async () => {   
    try {        
        const { data } = await axiosClient(`/catalogs/hotels/`)
        
        return { ok: true, data}

    } catch (error) {
        return { ok: false, message: error.response.data.error.message}
    } finally {
        consoleMsgFinally('getHotels end')
    }
}


export const getInitialContentDashboard = async (context, payload) => {
    const {  idContract, isAnual, year, idOwner, housingid, language, resort, isOwner } = payload

    try {
        const content = await Promise.all([
            fetchLanguages(), getHotelPerformance(context, {language, resort, isOwner}), fetchMyProfits(context, {idContract, isAnual, year}), getLastTransactions({idContract}), fetchNewsletter(),
            getNextClosestCheckIn({idOwner, housingid, idContract}), getPhotosGalleryUser(context, {idOwner})
        ])
        const [  languages, hotelPerformance, myProfits, lastTransactions, newsletters, nextReservation, gallery ] = content
        return {  languages, hotelPerformance, myProfits, lastTransactions, newsletters, nextReservation, gallery  }
    } catch (error) {
        console.log(error, error?.response?.data?.error?.message);
        return { ok: false, message: error?.response?.data?.error?.message }
    } finally {
        consoleMsgFinally('getInitialContentDashboard end')
    }
}

export const getInitialContentAccountOwner = async (context, payload) => {  
    const {  idOwner } = payload 
    
    try {        
        const content = await Promise.all([
            fetchLanguages(),  getPhotosGalleryUser(context, {idOwner})  
        ])
    const [  languages, gallery ] = content   
        return {  languages, gallery  }
    } catch (error) {
        return { ok: false, message: error?.response?.data?.error?.message }
    } finally {
        consoleMsgFinally('getInitialContentAccountOwner end')
    }
}

export const getContentTopCards = async (context, payload) => {  
    try {
        const content = await Promise.all([ fetchNewsletter(), getNextClosestCheckIn(payload) ])
        const [  newsletters, nextReservation ] = content
        return {  newsletters, nextReservation  }
    } catch (error) {
        console.log(error, error?.response?.data?.error?.message);
        return { ok: false, message: error?.response?.data?.error?.message }
    } finally {
        consoleMsgFinally('getContentTopCards end')
    }
}


export const getBenefitsByContract = async (context, payload) => {
    const { id, year } = payload
    try {
        const { data } = await axiosClient(`/fivesClub/getTransactions/`, { params: { id, year, isweb: true } })
        const { status, data:datos } = data
        return status ? formatBenefits(datos) : []
    } catch (error) {
        console.warn(error?.response?.data || error)
        return []
    } finally {
        consoleMsgFinally('getBenefitsByContract end')
    }
}

export const getDetailsTransactionsBenefit = async (context, payload) => {  
    const { id } = payload
    try {        
        const { data } = await axiosClient(`/fivesClub/getTransactionsDetail/`, { params: { id } })                   
        const { status, data:datos } = data 
        return status ? datos : []
    } catch (error) {                
        return error.response.data //false cuando es un bad request, el message error error.response.data 
    } finally {
        consoleMsgFinally('getDetailsTransactionsBenefit end')
    }
}

export const fetchMyProfits = async (context, payload ) => {
    const { idContract, isAnual, year } = payload
    const userId = store.state.auth?.userAdmin?.idUser || null

    try {
        const { data } = await axiosClient(`/fivesClub/getProfitGeneral/`, { params: { idContract, isAnual, year, userId } })
        const { status, data:datos } = data 
        return status ? datos : null
    } catch (error) {
        const response = error?.response?.data
        if (response){
            const {status, message} = response
            console.warn(message)
            return status || false
        } else return false
    } finally {
        consoleMsgFinally('fetchMyProfits end')
    }
}

export const fetchMonthlyProfit = async (context, payload ) => {
    try {
        const { data } = await axiosClient(`/fivesClub/getMonthlyProfit/`, { params: {...payload} })
        const { status, data:datos } = data
        return status ? datos : null
    } catch (error) {
        const response = error?.response?.data
        if (response){
            const {status, message} = response
            console.warn(message)
            return status || false
        } else return false
    } finally {
        consoleMsgFinally('fetchMonthlyProfit end')
    }
}

export async function fetchCondoFeeHousingDetail(context, payload) {
    try {
        const response = await axiosClient(`/fivesClub/getOwnerCondoFeeHousingDetail/`, {params: {...payload} })
        const respuesta = response && response.data
        const { data } = respuesta
        return data
    } catch (error) {
        
        const respuesta = error?.response?.data
        return respuesta?.data || false
    } finally {
        console.log('fetchCondoFeeHousingDetail end')
    }
}

export const getHotelPerformance = async (context, payload) => {  
   
    try {        
        const { data } = await axiosClient(`/fivesClub/getHotelPerformance/`, { params: { ...payload} } )                           
        const { status, data:datos } = data 
        return status ? datos : false
    } catch (error) {
        console.warn(error)
        return false //false cuando es un bad request, el message error error.response.data 
    } finally {
        consoleMsgFinally('getHotelPerformance end')
    }
}

export const getNextClosestCheckIn = async (payload) => {
    try {
        const { data } = await axiosClient(`/fivesClub/getnextReservationdate/`, { params: payload })
        const { status, date:datos, ownerInHouse } = data

        return status ? {datos, ownerInHouse} : null //un string de fecha   
    } catch (error) {
        console.log(error.response.data)
        return null
    } finally {
        consoleMsgFinally('getNextClosestCheckIn end')
    }
}


export const getLastTransactions = async (payload) => {
    const { idContract } = payload
    try {
        const { data } = await axiosClient(`/fivesClub/getTransactionDash/`, { params: { idContract, isweb: true } })
        const { status, data:datos } = data
        return status ? datos : []
    } catch (error) {
        console.warn(error?.response?.data || error)
        return []
    } finally {
        consoleMsgFinally('getLastTransactions end')
    }
}

export const fetchNewsletter = async ( ) => {      
    const language = store.state.auth.user?.languageId || 2    
    try {        
        const { data } = await axiosClient(`/fivesClub/getNewsLetters/`, { params: { language, isOwner: true } } )                   
        const { status, data:datos } = data 
        return status ? datos : []
    } catch (error) {                
        return error.response.data 
    } finally {
        consoleMsgFinally('getNewsletter end')
    }
}

export const autorizeSettlement = async (context, payload) => {        
    try {        
        const { data } = await axiosClient.put(`/fivesClub/authorizedLiquidation/`, payload )                            
        return data
    } catch (error) {                
        return error.response.data  
    } finally {
        consoleMsgFinally('autorizeSettlement end')
    }
}

export const fetchBookings = async (context, payload) => {
    try {        
        const { data } = await axiosClient(`/fivesClub/getOwnerBooking/`, { params: {  ...payload, isweb: true } } )                               
        const { status, data:datos } = data 
        return status ? orderBookingsByDateInAsc(datos) : []
    } catch (error) {                
        return error.response.data  
    } finally {
        consoleMsgFinally('fetchBookings end')
    }
}

export const fetchBookingsPenalities = async (context, bookingId) => {

    try {
        const { data } = await axiosClient( `/fivesClub/getOwnerBookingPenality/`, { params: {  idBooking: bookingId } } )
        const { status, penality } = data
        return status ? penality : status
    } catch (error) {
        console.log(error?.response?.data)
        return false
    } finally {
        consoleMsgFinally('fetchBookingsPenalities end')
    }
}

export async function  cancelOwnerBooking(context, payload) {
    try {
        const response = await axiosClient.put(`/fivesClub/cancelOwnerBooking/?`, payload)
        const respuesta = response && response.data
        return respuesta
    } catch (error) {
        
        const response = error.response && error.response.data
        console.warn(response?.message || response)
        return false
    } finally {
        console.log('cancelOwnerBooking end')
    }
}

export const getRooms = async (context, payload) => {  
        
    try {        
        const { data } = await axiosClient.post(`/bookAvailable/`, payload )                               
        const { status } = data
        if( status ){                        
            if( data.data && data.data.length > 0  ){                
                return data.data
            } else {                
                return  []
            }
        } else {
            return []
        }
    } catch (error) {                
        return error.response.data  
    } finally {
        consoleMsgFinally('getRooms end')
    }
}

export const fetchBenefitsByYearAndContract = async (context, payload) => {  
    const { idContract, year } = payload    
    
    try {        
         const { data } = await axiosClient(`/fivesClub/getBenefitsYear/`, { params: { idContract, year } } )                               
        const { status, data:datos } = data 
        return status ? datos : []
    } catch (error) {                
        return error.response.data  
    } finally {
        consoleMsgFinally('fetchBenefitsByYearAndContract end')
    }
}


export const fetchBenefitsByYearContract = async (context, payload) => {  
    const { idContract, year, typeBenefit, benefit } = payload    
    
    try {        
         const { data } = await axiosClient(`/fivesClub/getBenefitsYear/`, { params: { idContract, year, typeBenefit, benefit } } )                               
        const { status, data:datos } = data 
        return status ? datos : []
    } catch (error) {                
        return error.response.data  
    } finally {
        consoleMsgFinally('fetchBenefitsByYearContract end')
    }
}


export const validateBooking = async (context, dataBooking) => {
    try {
        const { data } = await axiosClient(`/fivesClub/getOwnerBookingBlack/`, { params: { ...dataBooking } } )
        return data
    } catch (error) {
        console.warn(error.response.data)
        return false
    } finally {
        consoleMsgFinally('validateBooking end')
    }
}

export const updateBooking = async (context, payload) => {
    try {
        const { data } = await axiosClient.put(`/fivesClub/getOwnerBooking/`, payload )
        return data
    } catch (error) {
        
        return error?.response?.data || false
    } finally {
        consoleMsgFinally('updateBooking end')
    }
}


export const bookingModifiedOwner = async (context, payload) => {
    try {
        const respuesta = await axiosClient.post(`/fivesClub/bookingModifiedOwner/`, payload )
        const { status } = respuesta
        if (status) showAlertMessage( 'Se actualizo la reserva', 'InfoIcon', '🎉🎉La actualización ha sido exitosa', 'success', 4000, 'bottom-right')
        return true
    } catch (error) {
        
        return false
    } finally {
        consoleMsgFinally('bookingModifiedOwner end')
    }
}

export const sendBooking = async (context, payload) => {        
    try {
        const { data } = await axiosClient.post(`/fivesClub/getOwnerBooking/`, payload )
        return data
    } catch (error) {
        return error.response.data
    } finally {
        consoleMsgFinally('sendBooking end')
    }
}

export const saveBookingOwner = async (context, payload) => {        
    try {        
        const { data } = await axiosClient.post(`/fivesClub/createBookingOwner/`, payload )                                                  
        return data
    } catch (error) {  
        console.log(error.response.data)              
        return error.response.data  
    } finally {
        consoleMsgFinally('saveBookingOwner end')
    }
}

export const saveBenefitsBookingOwner = async (context, payload) => {        
    try {        
        const { data } = await axiosClient.post(`/fivesClub/benefitsBookingOwner/`, payload )                                                  
        return data
    } catch (error) {  
        console.log(error.response.data)              
        return error.response.data  
    } finally {
        consoleMsgFinally('saveBenefitsBookingOwner end')
    }
}

export const fetchBenefitsBookingOwner = async (context, dataBooking) => {        
    try {        
        const { data } = await axiosClient(`/fivesClub/benefitsBookingOwner/`, { params: { ...dataBooking } } )                                                  
        return data
    } catch (error) {  
        console.log(error.response.data)              
        return error.response.data  
    } finally {
        consoleMsgFinally('fetchBenefitsBookingOwner end')
    }
}

export const updateBenefitsBookingOwner = async (context, payload) => {        
    try {        
        const { data } = await axiosClient.put(`/fivesClub/benefitsBookingOwner/`, payload )                                                  
        return data
    } catch (error) {  
        console.log(error.response.data)              
        return error.response.data  
    } finally {
        consoleMsgFinally('updateBenefitsBookingOwner end')
    }
}

export const deleteBenefitBooking = async (context, payload ) => {
    try {
        const { status } = await axiosClient.delete(`/fivesClub/benefitsBookingOwner/`, { params: { ...payload } } )
        return status
    } catch (error) {
        console.error(error, error?.response?.data)
        return false
    } finally {
        consoleMsgFinally('deleteBenefitBooking end')
    }
}



export const fetchResources = async (context, payload) => {        
    try {
        const { data } = await axiosClient(`/fivesClub/getgeneraldocumentsData/`, {params: {...payload}})
        const { status, data:datos } = data
        const filesFormated = status ? formatArrayFileDocuments(datos) : []
        return filesFormated
        // return splitArrayFileDocuments(filesFormated)
    } catch (error) {
        console.log(error);
        return error.response.data
    } finally {
        consoleMsgFinally('fetchResources end')
    }
}

export const fetchFilesByContracts = async (context, payload) => {   
    const { idContract, idType } = payload   
          
    try {                
        const { data } = await axiosClient(`/fivesClub/contractdocumentsData/`, { params: { idContract,idType } } )
        const { status, data:datos } = data  
        const filesFormated = status ? formatArrayFileDocuments(datos) : []                      
        return splitArrayFileDocuments(filesFormated)
    } catch (error) {   
                     
        return error.response.data  
    } finally {
        consoleMsgFinally('fetchFilesByContracts end')
    }
}

export const fetchGetDocumentstype = async (context,) => {   
    try {                
        const { data } = await axiosClient(`/fivesClub/getDocumentstype/`, )
        const { status, data:datos } = data  
        return datos
    } catch (error) {   
                     
        return error.response.data  
    } finally {
        consoleMsgFinally('fetchGetDocumentstype end')
    }
}

export const fetchCancellationReasons = async (context, id) => {
    try {
        const response = await axiosClient(`/catalogs/cancelMotiveList/`, { params: { idCanal: id }})
        const respuesta = response && response.data
        context.commit('setCancellationReasons', respuesta) //muto state cancellationReasons

    } catch (error) {
        
        return []
    } finally {
        consoleMsgFinally('fetchGetDocumentstype end')
    }
}

export const fetchTermsConditions = async (context, payload) => {
    try {
        const { data } = await axiosClient(`/catalogs/terminosycondiciones/`, { params: { ...payload } } )
        return data
    } catch (error) {
        console.warn(error?.response?.data || error)
        return false
    } finally {
        consoleMsgFinally('validateBooking end')
    }
}

export  const getHousingOwners = async (context, datos) => {
    const {idHotel,numberHouse} = datos
    try {
        const response = await axiosClient( `/fivesClub/getHousingOwners/`,  { params: { idHotel, numberHouse }})
        const respuesta = response && response.data
        const { status, data } = respuesta       
        return status ? data : []
    } catch (error) {
        return []
    } finally {
        console.log('getHousingOwners end')
    }
}

export const signExecutiveReport = async (context, payload) => {
    try {
        const { status } = await axiosClient.put(`/fivesClub/authorizedLiquidation/`, payload )
        return status
    } catch (error) {
        console.warn(error.response.data)
        return false
    } finally {
        consoleMsgFinally('signExecutiveReport end')
    }
}

export const getExecutiveReportFile = async (context, payload) => {
    try {
        const respuesta = await axiosClient(`/fivesClub/getExecutiveReportFile/`, {params: {...payload}} )

        // const {data, status} = respuesta

        const fileURL = window.URL.createObjectURL(new Blob([respuesta]))
        const fileLink = document.createElement("a")
        fileLink.href = fileURL
        fileLink.setAttribute("download", 'executive-reports.pdf')
        document.body.appendChild(fileLink)
        fileLink.click()

    } catch (error) {
        console.warn(error.response.data)
        return false
    } finally {
        consoleMsgFinally('signExecutiveReport end')
    }
}

export const pushActionsService = async (context, payload) => {
    try {
        const response = await axiosClient.post(`/logs/event/`, payload )
        
        const respuesta = response && response.data // {status: true, message: 'Event Log Guardado'}
        return respuesta
    } catch (error) {
        console.warn(error?.response?.data || error)
        return false
    } finally {
        consoleMsgFinally('pushActionsService end')
    }
}

export const getGenericParameters = async (context, id) => {
    try {
        const response = await axiosClient(`/genericparameters/`, { params: { idParameter: id }} )

        const respuesta = response && response.data
        return respuesta?.status ? respuesta?.data : false
    } catch (error) {
        
        return false
    } finally {
        consoleMsgFinally('getGenericParameters end')
    }
}

export  const getPromosOwner = async (context, data) => {
    const { idContract, idLanguage } = data
    try {
        const response = await axiosClient( `/fivesClub/getOwnerPromosWeb/`,  { params: { idContract, idLanguage } })
        const respuesta = response && response.data
        const { status, data } = respuesta  
             
        if(status && data.length > 0 ){
            return { status: true, promos: data, message: 'ok' } 
        }
        return { status: false, promos: [], message: 'Promos not found' }
        
    } catch (error) {
        if( error.response && error.response.status === 400 ){
            return { status: false, promos: [], message: 'Error 404 not found'}
        }
        return { status: false, promos: [], message: 'sucedio un error'}
    } finally {
        console.log('getPromosOwner end')
    }
}

export  const getCansharePromosOwner = async (context, data) => {
    const { idContract } = data
    try {
        const response = await axiosClient(`/fivesClub/checkPromoDisclaimer/`,  { params: { idContract } })
        const respuesta = response && response.data
        const { status, data } = respuesta  
             
        if( status && data ){
            return { status: true, data, message: 'ok' } 
        }
        return { status: false, data:null, message: 'Record not found' }
        
    } catch (error) {
        if( error.response && error.response.status === 400 ){
            return { status: false, data: null, message: 'An error occurred'}
        }
        return { status: false, data: null, message: 'An error occurred'}
    } finally {
        console.log('getCansharePromosOwner end')
    }
}

export  const acceptPromosOwner = async (context, payload) => {
    
    try {
        const response = await axiosClient.post(`/fivesClub/checkPromoDisclaimer/`,  payload )
        const respuesta = response && response.data
        const { status, message } = respuesta  
             
        if( status  ){
            return { status, message } 
        }
        return { status: false, message: 'An error occurred with the reques' }
        
    } catch (error) {
        if( error.response && error.response.status === 404 ){
            return { status: false,  message: 'An error occurred'}
        }
        return { status: false, message: 'An error occurred'}
    } finally {
        console.log('acceptPromosOwner end')
    }
}


export  const fetchOwnerTransactionsAgreement = async (context, data) => {
    const { idContract } = data
    try {
        const response = await axiosClient(`/fivesClub/checkOwnerTransactionsAgreement/`,  { params: { idContract } })
        const respuesta = response && response.data
        const { status, data } = respuesta

        if( status && data ){
            return { status: true, data, message: 'ok' }
        }
        return { status: false, data:null, message: 'Record not found' }

    } catch (error) {
        if( error.response && error.response.status === 400 ){
            return { status: false, data: null, message: 'An error occurred'}
        }
        return { status: false, data: null, message: 'An error occurred'}
    } finally {
        console.log('getCansharePromosOwner end')
    }
}

export  const setOwnerTransactionsAgreement = async (context, payload) => {

    try {
        const response = await axiosClient.post(`/fivesClub/checkOwnerTransactionsAgreement/`,  payload )
        const respuesta = response && response.data
        const { status, message } = respuesta

        if( status  ){
            return { status, message }
        }
        return { status: false, message: 'An error occurred with the reques' }

    } catch (error) {
        if( error.response && error.response.status === 404 ){
            return { status: false,  message: 'An error occurred'}
        }
        return { status: false, message: 'An error occurred'}
    } finally {
        console.log('acceptPromosOwner end')
    }
}


export  const fetchOwnerValidateSigned = async (context, data) => {
    const { idContract } = data
    try {
        const response = await axiosClient(`/fivesClub/getValidateSignature/`,  { params: { idContract } })
        const respuesta = response && response.data
        const { status, validate } = respuesta

        if( status && validate ){
            return { status: true, validate, message: 'ok' }
        }
        return { status: false, validate:null, message: 'Record not found' }

    } catch (error) {
        if( error.response && error.response.status === 400 ){
            return { status: false, data: null, message: 'An error occurred'}
        }
        return { status: false, data: null, message: 'An error occurred'}
    } finally {
        console.log('fetchOwnerValidateSigned end')
    }
}

export  const checkIfOwnerIsSubscribedToExchanges = async (context, data) => {
    const { idContract } = data
    try {
        const response = await axiosClient(`/fivesClub/getExchangesSubscriptions/`,  { params: { idContract } })
        const respuesta = response && response.data

        return respuesta

        // return { status: true, data, message: 'ok' }
        // return { status: false, data:null, message: 'Record not found' }

    } catch (error) {
        console.warn(error)
        return { status: false, data: null, message: `An error occurred`}
    } finally {
        console.log('getCansharePromosOwner end')
    }
}

export const subscribeToExchanges = async (context, payload) => {

    try {
        const response = await axiosClient.post(`/fivesClub/getExchangesSubscriptions/`,  payload )
        const respuesta = response && response.data

        return respuesta

    } catch (error) {
        console.warn(error)
        return error?.response?.data || false
    } finally {
        console.log('subscribeToExchanges end')
    }
}


export const fetchStaffContacts = async (context, payload) => {

    try {
        const response = await axiosClient(`/fivesClub/getStaffContactOwners/` )
        const respuesta = response && response.data

        return respuesta?.data || []

    } catch (error) {
        console.warn(error)
        return error?.response?.data || []
    } finally {
        console.log('fetchStaffContacts end')
    }
}